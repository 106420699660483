.registration{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    height: 100vh;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,9,107,1) 68%, rgba(0,212,255,1) 100%);
}

.inputName{
    width: 40%;
    padding: 20px;
    font-size: 30px;
    background-color: rgba(0,212,255,1);
    text-align: center;
    margin-left: 30%;
    margin-bottom: 5%;
}

.btnLogin{
    background-color: purple;
    padding: 20px;
    width: 20%;
    font-size: 30px;
    margin-left: 40%;
}

.main{
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

@media (max-width: 675px) {
    .inputName{
        font-size: 20px;
        padding: 15px;
    }

    .btnLogin{
        font-size: 20px;
        color: red;
        padding: 15px;
    }
}

@media (max-width: 440px) {
    .inputName{
        font-size: 15px;
        padding: 10px;
    }

    .btnLogin{
        font-size: 15px;
        color: red;
        padding: 10px;
    }
}