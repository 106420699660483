.lobby {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

header {
    display: flex;
    background-color: rgb(128, 128, 128);
    height: 10%;
    align-items: center;
    justify-content: space-between;
}

.btnHeaderLobby {
    background-color: rgb(220, 213, 213);
    font-size: 15px;
    font-weight: bold;
    border-radius: 20px;
    padding: 1.5%;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70%;
}

.headerInfo {
    background-color: rgb(220, 213, 213);
    font-size: 5%;
    font-weight: bold;
    border-radius: 20px;
    padding: 1.5%;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70%;
    width: 250px;
    /*width: 200px;*/
    margin-right: 10px;
}

.waxLogo {
    background-color: orange;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: white;
}

.waxName {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.waxBalance {
    display: flex;
    align-items: center;
    justify-content: center;
    /*font-size: 20px;*/
    font-size: 20px;
    font-weight: bold;
}

.main {
    height: 80%;
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    flex-direction: row;
    /*background-image: url("./lobby.png");*/
    /*background-size: 100%;*/
}

.raceContainer {
    height: 64%;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: 3%;
}

.imgRace {
    height: 100%;
    width: 100%;
    border-radius: 10%;
}

.imgGarage {
    height: 100%;
    width: 100%;
    border-radius: 10%;
}

.imgLobby {
    height: 100%;
    width: 100%;
}

.btnRace {
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
    background-color: purple;
    border-radius: 20%;
    color: white;
    opacity: 0.5;
    position: absolute;
}

.btnGarage {
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
    background-color: purple;
    border-radius: 20%;
    color: white;
    opacity: 0.5;
    position: absolute;
}

.garageContainer {
    height: 64%;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    /*background-image: url("./garage.png");*/
    /*background-size: 100%;*/
    margin-right: 3%;
    margin-left: 52%;
}

.futter {
    background-color: rgb(128, 128, 128);
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

@media (max-height: 582px) {
    .headerInfo {
        width: 220px;
    }

    .waxLogo {
        height: 18px;
        width: 18px;
        font-size: 7px;
    }

    .waxName {
        font-size: 18px !important;
    }

    .waxBalance {
        font-size: 18px !important;
    }
}

/*@media (max-width: 940px) {*/
/*    .raceContainer {*/
/*        height: 30%;*/
/*        width: 80%;*/
/*        margin-left: 10%;*/
/*        margin-right: 10%;*/
/*        margin-top: 5%;*/
/*    }*/

/*    .garageContainer {*/
/*        height: 30%;*/
/*        width: 80%;*/
/*        margin-left: 10%;*/
/*        margin-right: 10%;*/
/*        margin-top: 300px;*/
/*    }*/

/*    .main {*/
/*        flex-direction: column;*/
/*    }*/
/*}*/