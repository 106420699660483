

header{
    height: 10%;
    display: flex;
    background-color: gray;
    align-items: center;
    justify-content: space-between;
}

.btnHeader, .headerInfo {
    background-color: rgb(220, 213, 213);
    font-size: 15px;
    font-weight: bold;
    border-radius: 20px;
    padding: 1.5%;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70%;
}

.headerInfo {
    width: 250px;
    margin-right: 10px;
}

.waxLogo {
    background-color: orange;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: white;
}

.waxName {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.waxBalance {
    display: flex;
    align-items: center;
    justify-content: center;
    /*font-size: 20px;*/
    font-size: 20px;
    font-weight: bold;
}

@media (max-height: 582px) {
    .headerInfo {
        width: 220px;
    }

    .waxLogo {
        height: 18px;
        width: 18px;
        font-size: 7px;
    }

    .waxName {
        font-size: 18px !important;
    }

    .waxBalance {
        font-size: 18px !important;
    }
}
