.home {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main {
    height: 100%;
    display: flex;
    flex-direction: row;
    /*background-image: url("./lobby.png");*/
    /*background-size: 100%;*/
}

.main div{
    position: absolute;
    color: white;
}

.imgHome {
    height: 100%;
    width: 100%;
}

.leftContent{
    height: 50%;
    width: 40%;
    margin-left: 5%;
    margin-top:  180px;
    position: absolute;

}

.gameName{
    font-size: 60px;
    text-align: center;
    margin-left: 14%;
}

.line{
    background-color: gray;
    width: 80%;
    height: 5px;
    margin-top: 80px;
    margin-left: 10%;
}

.first{
    margin-top: 120px;
    font-size: 30px;
    margin-right: 10px;
    margin-left: 14%;
}

.second{
    margin-top: 175px;
    font-size: 30px;
    margin-left: 14%;
}

.description{
    margin-top: 25%;
}

.rightContent{
    height: 50%;
    width: 40%;
    margin-left: 50%;
    margin-top: 290px;
    position: absolute;
}

.startGame{
    padding: 15px;
    background-color: purple;
    opacity: 0.5;
    color: white;
    font-size: 60px;
    font-weight: bold;
    border-radius: 10%;
}

@media (max-width: 845px){
    .startGame{
        font-size: 40px;
    }

    .first{
        font-size: 20px;
    }

    .second{
        font-size: 20px;
        margin-top: 155px;
    }
}

@media (max-width: 575px){
    .startGame{
        font-size: 20px;
    }

    .first{
        font-size: 15px;
    }

    .second{
        font-size: 15px;
        margin-top: 145px;
    }

    .gameName{
        font-size: 45px;
    }
}