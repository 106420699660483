.carType{
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carRarity{
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carHealthPanel{
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carHealth{
    width: 30%;
}

.carHealthScale{
    width: 70%;
    background-color: rgb(128, 128, 128);
}

.carHealthIndicator{
    background-color: green;
    display: flex;
    justify-content: center;
}

.carFuelPanel{
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
}

.carFuel{
    width: 30%;
}

.carFuelScale{
    width: 70%;
    background-color: rgb(128, 128, 128);
}

.carFuelIndicator {
    background-color: red;
    display: flex;
    justify-content: center;
}