.garage {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main {
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.imgGarage{
    height: 100%;
    width: 100%;
}

.imgCar {
    height: 100%;
    width: 100%;
}

.pageName{
    position: absolute;
    color: white;
    font-size: 40px;
    font-weight: bold;
}

.content{
    height: 60%;
    width: 90%;
    margin-top: 7%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.car{
    height: 90%;
    width: 20%;
    background-color: red;
    margin-left: 15%;

}

.mechanics{
    height: 90%;
    width: 40%;
    margin-right: 15%;

}

.carProperties{
    height: 80%;
    background-color: purple;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
    color: white;
    font-weight: bold;
    font-size: calc(1.1vw + 1.1vh);
    border-radius: 10%;
    opacity: 0.7;
}

.carType{
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carRarity{
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carHealthPanel{
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carHealth{
    width: 30%;
}

.carHealthScale{
    width: 70%;
    background-color: rgb(128, 128, 128);
}

.carFuelPanel{
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carFuel{
    width: 30%;
}

.carFuelScale{
    width: 70%;
    background-color: rgb(128, 128, 128);
}

.carBtns{
    height: 20%;
    display: flex;
    align-items: end;
}

.carBtns button{
    background-color: purple;
    font-size: 30px;
    padding: 10px;
    border-radius: 30%;
    color: white;
    opacity: 0.8;
}

.futter {
    background-color: rgb(128, 128, 128);
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

@media (max-height: 582px) {
    .headerInfo {
        width: 220px;
    }

    .waxLogo {
        height: 18px;
        width: 18px;
        font-size: 7px;
    }

    .waxName {
        font-size: 18px !important;
    }

    .waxBalance {
        font-size: 18px !important;
    }
}

@media (max-width: 1025px) {
    .carBtns button{
        background-color: purple;
        font-size: 25px;
        padding: 10px;
        border-radius: 30%;
        color: white;
    }
}

@media (max-width: 880px) {
    .carBtns button{
        background-color: purple;
        font-size: 20px;
        padding: 8px;
        border-radius: 30%;
        color: white;
    }

    .mechanics{
        height: 60%;
    }

    .car{
        height: 60%;
    }


}

@media (max-width: 670px) {
    .carBtns button{
        background-color: purple;
        font-size: 15px;
        padding: 7px;
        border-radius: 30%;
        color: white;
    }
}

@media (max-height: 580px) {
    .carBtns button{
        font-size: 20px;
        padding: 7px;
    }

    .carType, .carRarity, .carHealthPanel, .carFuelPanel{
        font-size: 20px;
        margin-bottom: 1%;
    }

}

@media (max-width: 1045px) {
    .carType, .carRarity, .carHealth,  .carFuel{
        font-size: 20px;
    }
}

@media (max-width: 710px) {
    .carType, .carRarity, .carHealth,  .carFuel{
        font-size: 15px;
    }
}
