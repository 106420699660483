.race {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

header {
    display: flex;
    background-color: rgb(128, 128, 128);
    height: 10%;
    align-items: center;
    justify-content: space-between;
}

.btnHeaderRace {
    background-color: rgb(220, 213, 213);
    font-size: 15px;
    font-weight: bold;
    border-radius: 20px;
    padding: 1.5%;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70%;
}

.headerInfo {
    background-color: rgb(220, 213, 213);
    font-size: 5%;
    font-weight: bold;
    border-radius: 20px;
    padding: 1.5%;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70%;
    width: 250px;
    /*width: 200px;*/
    margin-right: 10px;
}

.waxLogo {
    background-color: orange;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: white;
}

.waxName {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.waxBalance {
    display: flex;
    align-items: center;
    justify-content: center;
    /*font-size: 20px;*/
    font-size: 20px;
    font-weight: bold;
}

.imgRace{
    height: 100%;
    width: 100%;
}

.imgCar{
    height: 100%;
    width: 100%;
}

.imgRoad{
    height: 100%;
    width: 100%;
}

.main{
    height: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carPanel{
    height: 70%;
    width: 20%;
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 7%;
    position: absolute;
    margin-left: 2%;
}

.carPanelName{
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        color: white;
}

.currentCar{
    height: 40%;
    width: 80%;
    background-color: purple;
    margin-left: 10%;
    margin-right: 10%;
    opacity: 1;
}

.carProperties{
    height: 30%;
    width: 80%;
    background-color: rgba(128, 128, 128, 0.9);
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 8%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 1.2vw;
    flex-direction: column;
}

.carType{
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carRarity{
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carHealthPanel{
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}

.carHealth{
    width: 30%;
}

.carHealthScale{
    width: 70%;
    background-color: rgb(128, 128, 128);
}

.carFuelPanel{
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
}

.carFuel{
    width: 30%;
}

.carFuelScale{
    width: 70%;
    background-color: rgb(128, 128, 128);
}

.carBtns{
    height: 10%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.carBtns button{
    width: 35%;
    padding: 10px;
    border-radius: 15%;
    font-size: 15px;
    font-weight: bold;
    background-color: rgb(128, 128, 128);
    color: white;
    opacity: 0.9;
}

.racePanel{
    height: 70%;
    width: 50%;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: 24%;
}

.map{
    height: 60%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    opacity: 0.3;
}

.road{
    height: 40%;
    background-color: rgb(128, 128, 128);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("road.png");
    background-size: 100%, 100%;
}

.road button{
    width: 30%;
    padding: 20px;
    font-weight: bold;
    font-size: 20px;
    margin: 20px;
    opacity: 0.4;
}

.statsPanel{
    height: 70%;
    width: 20%;
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 7%;
    position: absolute;
    margin-left: 76%;
}

.statsPanelName{
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    color: white;
}

.statsInfo{
    height: 90%;
    margin-top: 10%;
}

.leaderInfo{

}

.statsInfo div{
    margin-bottom: 5%;
    height: 10%;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    background-color: rgba(128, 128, 128, 0.9);
}

.futter {
    background-color: rgb(128, 128, 128);
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

@media (max-height: 582px) {
    .headerInfo {
        width: 220px;
    }

    .waxLogo {
        height: 18px;
        width: 18px;
        font-size: 7px;
    }

    .waxName {
        font-size: 18px !important;
    }

    .waxBalance {
        font-size: 18px !important;
    }

    .statsInfo div{
        font-size: 15px;
    }

    .carBtns button{
        padding: 5px;
        font-size: 10px;
    }
}

@media (max-width: 1020px) {

    .carBtns button{
        padding: 5px;
        font-size: 10px;
    }
}

@media (max-width: 730px) {

    .carBtns button{
        padding: 2px;
        font-size: 8px;
    }
}

@media (max-width: 728px) {
    .road button{
        padding: 10px;
        font-size: 14px;
    }

    .statsInfo div{
        font-size: 10px;
    }
}

@media (max-height: 440px) {
    .road button{
        padding:   10px;
        font-size: 10px;
    }
}

@media (max-width: 765px) {
    .statsPanelName{
        font-size: 20px;
    }
}

@media (max-width: 511px) {
    .statsPanelName{
        font-size: 10px;
    }
}

@media (max-width: 1340px) {
    .statsInfo div{
        font-size: 15px;
    }
}

@media (max-width: 1008px) {
    .statsInfo div{
        font-size: 10px;
    }
}

@media (max-width: 700px) {
    .statsInfo div{
        font-size: 8px;
    }
}
